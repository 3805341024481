import React, { useState, useEffect } from 'react';
import * as MUI from '@mui/material';
import Footer from './Footer';
import { saveAs } from 'file-saver';


const { TextField, Button, Snackbar, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography } = MUI;

function Certificateverification() {
  const [studentId, setStudentId] = useState('');
  const [studentPassword, setStudentPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleLogin = async () => {
    setIsLoading(true);

    try {
        // Check if both graduate ID and password are filled
      if (!studentId || !studentPassword) {
          throw new Error('Please fill in both Graduate ID and Password');
        }
  
      // Check student ID and password
      if (studentId !== 'RV44837' && studentPassword !== '54901990') {
        throw new Error('Student Not Found');
      }

      // Simulate API call with delay (replace with actual API call)
      const response = await new Promise((resolve) => setTimeout(() => resolve({
        name: 'Gomeri Kombo',
        dateOfBirth: 'December 16, 1970',
        yearOfGraduation: 2017,
        programEnrolled: 'Bachelor of Business Administration',
        major: 'Hospitality Management',
        gpa: 3.49,
        distinctions: ['Introduction to Hospitality Accounting'],
        awards: ['Award of Excellence in Hospitality Management'],
      }), 1000));

      setStudentData(response);
      setOpenSnackbar(false); // Close the Snackbar if it was previously open
    } catch (error) {
      console.error('Error fetching data:', error);
      setOpenSnackbar(true); // Open the Snackbar for error handling
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Check if we're on the /navigation path
    if (window.location.pathname.startsWith('/verification')) {
        const targetDiv = document.getElementById('page');
        if (targetDiv) {
          targetDiv.style.display = 'none';
        }
      }
    // Clear student data when component unmounts to prevent stale data
    return () => setStudentData(null);
  }, []);


  //  const certificateFilePath = 'https://drive.google.com/uc?id=1WwKZVSHq8DKPumpxy4glAngO2z0vGzxI';


  const handleDownloadCertificate = async () => {
    try {
      // Assuming the PDF file is hosted on Amazon S3
      const certificateFilePath = 'https://neilwilsonbucket.s3.amazonaws.com/Verification+Certificate.pdf';
  
      // Use the file-saver library to trigger the download
      saveAs(certificateFilePath, 'Verification_Certificate.pdf');
    } catch (error) {
      console.error('Error downloading certificate:', error);
    }
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <MUI.Container maxWidth="sm">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '10%' }}>
            <img src="/neilwilsonuniversityofficial.files.wordpress.com/2017/04/cropped-neil-wilson-university.jpg" alt="Logo" style={{ width: 'auto', height: 'auto', marginBottom: '20px' }} />
          </div>
          <Typography style={{ textAlign: 'left', color: 'black', marginBottom: '10%' }}>
            <h5>Student Verification Portal</h5>
          </Typography>
          <TextField
            label="Graduate ID"
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
            fullWidth
            required
            margin="normal"
            sx={{ marginTop: '8px' }}
            variant='standard'
            style={{ color: 'black' }}
          />
          <TextField
            label="Password"
            type="password"
            value={studentPassword}
            onChange={(e) => setStudentPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
            sx={{ marginTop: '8px' }}
            variant='standard'
            style={{ color: 'black' }}
          />
          <Button variant="contained" color="primary" disableRipple onClick={handleLogin} disabled={isLoading} style={{marginBottom:'5rem'}}>
            Verify
          </Button>

          {studentData && (
            <>
              <Typography style={{ textAlign: 'center', color: 'black' }}>
                <h3>This is to verify that</h3>
                <h4 style={{ fontFamily: 'Cambria', fontWeight: 'bold' }}>{studentData.name}</h4>
                <h6>Has successfully completed the Bachelor's Program from Neil Wilson University in the year 2017</h6>
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
                <h7>The particulars for {studentData.name} are as follows:</h7>
              </Typography>

              {/* Watermark container */}
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                <TableContainer sx={{ marginTop: 4 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell>{studentData.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Date of Birth</TableCell>
                        <TableCell>{studentData.dateOfBirth}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Year of Graduation</TableCell>
                        <TableCell>{studentData.yearOfGraduation}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Program Enrolled</TableCell>
                        <TableCell>{studentData.programEnrolled}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Major</TableCell>
                        <TableCell>{studentData.major}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: 'black', fontWeight: 'bold' }}>GPA</TableCell>
                        <TableCell>{studentData.gpa}</TableCell>
                      </TableRow>
                      {studentData.distinctions && (
                        <TableRow>
                          <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Distinctions</TableCell>
                          <TableCell>{studentData.distinctions.join(', ')}</TableCell>
                        </TableRow>
                      )}
                      {studentData.awards && (
                        <TableRow>
                          <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Awards</TableCell>
                          <TableCell>{studentData.awards.join(', ')}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10%',
                    transform: 'translate(-50%, -50%) rotate(-20deg)', // Adjust angle as desired
                    color: 'red',
                    fontSize: '60px',
                    fontWeight: 'bold',
                    opacity: 0.1, // Adjust opacity for transparency
                  }}
                >
                 
                </div>
              </div>

               {/* Button to download the verification certificate */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadCertificate}
            style={{ marginTop: '20px' }}
            disableRipple  
          >
            Download Certificate
          </Button>

            
            </>
          )}
        </MUI.Container>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MUI.Alert severity="error">Student Not Found</MUI.Alert>
      </Snackbar>
    
    </div>
   <Footer></Footer>
   </>
  );
}

export default Certificateverification;

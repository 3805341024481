import React from 'react';
import { Typography } from '@mui/material';

function Footer() {
  return (
    <div style={{
    
      justifyContent: 'center', // Center content horizontally
      alignItems: 'center', // Center content vertically
      backgroundColor: 'white', // Optional: Adjust background color as desired
      padding: '1rem', // Optional: Add padding as needed
    }}>
      <Typography variant="body2" color="textSecondary" style={{textAlign:'center'}}>
        © {new Date().getFullYear()} | Neil Wilson University | All Rights Reserved.
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{textAlign:'center'}}>
        For any inquiries regarding admissions, verification of transcripts and certificates, please contact&nbsp;
        <a href="mailto:admin@neilwilsonuniversity.com" style={{color: 'blue' , textDecoration: 'underline' }}>admin@neilwilsonuniversity.com</a>
      </Typography>
    </div>
  );
}

export default Footer;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Certificateverification from './Components/Certificateverification/Certificateverification';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/verification" element={<Certificateverification />} />
        <Route path="/verification/" element={<Certificateverification />} />
      </Routes>
    </Router>
  );
}

export default App;
